@font-face {
    font-family: blair;
    src: url(./Fonts/blair-itc-medium.ttf);
  }
html,body{
    font-family: blair;
  }
::-moz-selection { /* Code for Firefox */
    color: rgb(255, 255, 255);
    background: #FFED00;
  }
  
::selection {
    color: rgb(255, 255, 255);
    background: #FFED00;
  }