@font-face {
    font-family: blair;
    src: url(../../Fonts/blair-itc-medium.ttf);
  }
.sluzby-page{
    min-height: 100vh;
    padding-top: 80px;
}
.sluzby-bg{
    background-image: linear-gradient(rgba(0,0,0,0.55),rgba(0,0,0,0.55)), url(../../Images//Sluzby/sluzby-background.jpg);
    background-size: cover;
}
.sluzby-main{
    text-align: center;
    padding-top: 100px;
    padding-bottom: 100px;
    color: white;
}
.sluzby-text{
    text-align: center;
    color: black;
    font-family: Arial;
    font-size: 28px;
    padding-left: 10%;
    padding-right: 10%;
}
.sluzby-znak{
    display: inline;
    font-size: 30px;
    color: #FFED00;
}
.sluzby-line{
    border-top: 2px solid #FFED00;
}
.sluzby-produkty-text{
    text-align: center;
    text-transform: uppercase;
    margin-top: 20px;
}
.sluzby-carousel-container{
    padding-left: 10%;
    padding-right: 10%;
}
.sluzby-carousel-img{
    width: 100%;
    padding: 20px;
}
.sluzby-eshop{
    background-color: #FFED00;
    width: 100%;
    padding-left: 10%;
    padding-right: 10%;
    height: auto;
    padding-top: 50px;
    padding-bottom: 50px;
    
}
.sluzby-eshop-grid{
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
    height: 100%;
    justify-items: center;
    align-items: center;
}
.sluzby-eshop-text{
    font-family: Arial, Helvetica, sans-serif;
    font-size: 28px;
    text-align: center;
}
.sluzby-eshop-button{
    background-color: black;
    color: white;
    border: none;
    padding: 12px;
    font-size: 28px;
    cursor: pointer;
}
.sluzby-eshop-button:hover{
    color: white;
    background-color: rgb(20, 20, 20);
}


@media (max-width:700px){
    .sluzby-text{
        padding: 5px;
    }
    .sluzby-carousel-container{
        padding: 0px;
    }
}
@media (max-width:850px){
    .sluzby-eshop-grid{
        grid-template-columns: 1fr;
        grid-gap: 20px;
    }
    .sluzby-eshop-button{
        font-size: 24px;
    }
}
.sluzby-kontakt{
    font-weight: bold;
    color: black;
}
.sluzby-kontakt:hover{
    color: #FFED00;
}
.tisk-moznosti{
    font-family: blair;
    text-transform: uppercase;
}