.kontakt-background{
    background-image: url(../../Images/kontakt/kontakt-bg.jpg);
    background-size: cover;
    background-position:center;
    background-color:#FFED00;
    padding-top: 140px;
    position: relative;
    padding-left: 20%;
    padding-right: 20%;
    padding-bottom: 40px;
    min-height: 100vh;
}
.kontaktgrid1-container{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    position: relative;
    grid-gap: 3%;
    width: 100%;
}
.kontaktgrid1-item{
    background-color: black;
    width: 100%;
    height: auto;
    text-align: center;
    padding-bottom: 10px;
}
.kontaktgrid1-main{
    text-align: center;
    color: white;
    margin-top: 10px;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
}
.kontaktgrid1-logo{
    font-size: 50px;
    color: #FFED00;
    margin-top: 20px;
    position: relative;
   
}
.kontaktgrid1-text{
    font-family: Arial, Helvetica, sans-serif;
    text-align: center;
    font-size: 18px;
    color: white;
}
.kontaktgrid1-znak{
    color: #FFED00;
    display: inline;
}

.kontakt-container-map{
    padding-left: 10%;
    padding-right: 10%;
    padding-top: 10px;
}
.kontakt-map{
    width: 100%;
    height: 500px;
    position: relative;
    border: 3px solid #FFED00;
}
#kontaktgrid1-text-href:hover{
    color: #FFED00;
}

/*laptop*/
@media (max-width: 1400px){
    .kontaktgrid1-container{
        display: grid;
        grid-template-columns: 1fr 1fr;
        position: relative;
        grid-gap: 3%;
        width: 100%;
    }
    .kontakt-background{
        padding-left: 10%;
        padding-right: 10%;
        padding-bottom: 40px;
        min-height: 100vh;
    }
    .kontakt-container-map{
        padding-top: 20px;
    }
}

/*mobile*/
@media (max-width: 650px){
    .kontaktgrid1-container{
        display: grid;
        grid-template-columns: 1fr;
        position: relative;
        grid-gap: 0%;
        width: 100%;
    }
    .kontakt-background{
        padding-left: 0%;
        padding-right: 0%;
        padding-bottom: 0px;
        min-height: 100vh;
        padding-top: 80px;
    }
    .kontakt-container-map{
        margin: 0;
        padding: 0;
    }
    .kontakt-background{
        background-image: none;
        padding: none;
        margin: none;
        background-color: #212529;
    }
    .kontaktgrid1-item{
        background-color: #414141;
        border: none;
        box-shadow: none;
        padding-bottom: 10px;

    }
    .kontakt-map{
        border: none;
    }
}