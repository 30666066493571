/*navbar style*/
.navbar{
    padding-top: 0px;
    padding-bottom: 0px;
    background-color: black;
    width: 100%;
    height:  80px;
  }
  .navbar-up{
    background-color: black;
    height: 80px;
  }
  
  /*end of navbar style*/
  
  /*logo*/
  img{
    margin-top: 25px;
    margin-bottom: 25px;
  }
  .navbar-brand{
    padding-top: 0px;
    padding-bottom: 0px;
  }
  /*end of logo*/
  .nav-link{
    color: white;
    font-size: 18px;
    height: 80px;
    padding-top: 25px;
    padding-right: 10px;
    text-align: center;
    font-weight: bold;
    position: relative;
    text-decoration: none;
  }
  .navbar-nav .nav-link.active, .navbar-nav .show>.nav-link{
    color: white;
  }
  .navbar-nav:hover .nav-link.active:hover, .navbar-nav:hover .show>.nav-link:hover{
    color: #FFED00;
  }
  #link-eshop{
    color: white;
    font-size: 18px;
    height: 80px;
    padding-top: 25px;
    padding-right: 10px;
    text-align: center;
    font-weight: bold;
    position: relative;
    text-decoration: none;
  }
  
  .nav-link:hover{
    color: #FFED00;
  }
  #link-eshop:hover{
    color: #FFED00;
  }
  @media (min-width:1475px){
    .navbar-expand-xl .navbar-nav .nav-link {
        margin-right: 30px;
        margin-left: 30px;
      }
      #link-eshop{
        margin-right: 30px;
        margin-left: 30px;
      }
  }

  @media (min-width: 1200px) and (max-width:1475px){
    .navbar-expand-xl .navbar-nav .nav-link {
        margin-right: 8.5px;
        margin-left: 8.5px;
      }
      #link-eshop{
        margin-right: 8.5px;
        margin-left: 8.5px;
      }
  }
  @media (min-width: 1200px ){

    .navbar{
      padding-left: 12%;
      padding-right: 10%;
    }
    
  }
  @media (max-width: 1199px){
    .link-color{
      background-color: black;
    }
    .link-color2{
      background-color: black;
    }
    .navbar-brand{
      margin-left: 20px;
    }

  
  }
  
  /*Start of Toggler*/
  .navbar-toggler{
    width: 30px;
    height: 30px;
    position: relative;
    transition: .5s ease-in-out;
  }
  .navbar-toggler {
    margin-right: 20px;
  }
  .navbar-toggler,
  .navbar-toggler:focus,
  .navbar-toggler:active,
  .navbar-toggler-icon:focus{
    outline: none;
    box-shadow: none;
    border: none;
  }
  .navbar-toggler span{
    margin: 0;
    padding: 0;
  }
  .toggler-icon{
    display: block;
    position: absolute;
    height: 3px;
    width: 100%;
    background-color: white;
    border-radius: 1px;
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: 0.5s ease-in-out;
  }
  .middle-bar{
    margin-top: 0px;
  }
  /*when navigation is clicked*/
  .navbar-toggler .top-bar{
    margin-top: 0px;
    transform: rotate(135deg);
  
  }
  .navbar-toggler .middle-bar{
    opacity: 0;
    filter: alpha(opacity=0);
    
  }
  .navbar-toggler .bottom-bar{
    margin-top: 0px;
    transform: rotate(-135deg);
  }
  /*navbar is collapsed*/
  .navbar-toggler.collapsed .top-bar{
    margin-top: -12px;
    transform: rotate(0deg);
  }
  .navbar-toggler.collapsed .middle-bar{
    opacity: 1;
    filter: alpha(opacity=100);
  }
  .navbar-toggler.collapsed .bottom-bar{
    margin-top: 12px;
    transform: rotate(0deg);
  }
  
  /*End of Toggler*/
  
  /*dropdown*/
  .dropdown{
    position: absolute;
    width: 300px;
    transform: translateX(-25%);
    text-align: center;
    overflow: hidden;
    transition-timing-function: ease-in;
    transition: 0.7s;
    border-radius: 0px 0px 5px 5px;
    background-color: black;
  }
  .dropdown-bg{
    background-color: black;
  }
  .dropdown-item{
    text-align: center;
    align-items: center;
    display: flex;
    color: white;
    font-size: 16px;
    text-align: center;
    position: relative;
    font-weight: bold;
  }
  .dropdown-item:hover{
    color: #FFED00;
  }
  @media (min-width: 1200px) {
    #drop-item{
        margin-left: 20px;
      }
  }

  #drop-item::after{
    content: none;
  }
  #drop::after{
    content: none;
  }
  @media (max-width: 1199px){
    .dropdown{
      transform: translateX(0px);
      justify-content: center;
      width: 100%;
      position: relative;
      background-color: #292929;
    }
    .dropdown-item{
      justify-content: center;
    }
    .dropdown-item:hover{
      color: #FFED00;
    }
    #drop{
      background-color: black;
    
    }
    
  }
  .dropdown-icon{
    position: absolute;
  }
  
  /*end of dropdown*/